<template>
    <section id="methods">
        <div class="row mx-0">
            <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <h3>Onze werkwijze</h3>
            </div>
            <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <div class="row">
                    <div class="col-12">
                        <p>Wij zijn er voor het welbevinden van mensen. Wij willen dicht bij de mensen staan. Om hen een perspectief te geven op een zinvolle deelname aan het maatschappelijk leven. Wij zetten ons met name in voor die
                            mensen, bij wie dat perspectief (tijdelijk) ontbreekt en dreigen aan de kant te komen staan.
                            Wij helpen mensen vooruit door hun grenzen te verleggen aan de hand van activiteiten en sociale verbanden in de wijk. Wij laten mensen (weer) eigen kracht ervaren, bij voorkeur door talenten van mensen aan elkaar
                            te verbinden. Dit draagt bij aan de versterking van sociale contacten, saamhorigheid en prettig wonen.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Methods',
}
</script>

<style scoped>
.row {
    background-color: var(--light-green);
    padding: 50px 0;
}

@media (max-width: 425px) {
    .row {
        padding: 15px 0;
    }
}

@media (min-width: 991px) {
    h4 {
        font-size: 25px;
    }

    p {
        font-size: 20px;
    }
}
</style>
