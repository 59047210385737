<template>
    <section id="foundation">
        <div class="row mx-0">
            <div class="col-12 col-md-5 order-last order-md-first text-center text-md-start">
                <img class="w-100 photo mx-auto" src="~@/assets/images/work/work.jpg" alt="Hoe werken wij">
            </div>
            <div class="col-12 col-md-7 order-first order-md-last">
                <div class="row align-items-center h-100">
                    <div class="col-12 mx-auto">
                        <div>
                            <h3>De stichting</h3>
                            <p>
                                Stichting Mensch is ontstaan in 2014 doordat we hebben gezien dat er in het werkveld van welzijn teveel fragmentatie is van (zelf)organisaties.
                                Dit is jammer en komt niet ten goede van de inwoners. Wij als Stichting Mensch trachten om de fragmentatie te verminderen door
                                organisaties, aanbod en mensen aan elkaar te koppelen. Op een manier dat we generalistisch inzetten, maar wel maatwerk leveren.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Foundation',
}
</script>

<style scoped>
@media (min-width: 991px) {
    p {
        font-size: 20px;
    }
}

img {
    border-radius: 10px;
}

.photo {
    max-width: 450px
}
</style>
